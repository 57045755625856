@import "globals";


.WindowResume {
  position: relative; // needed for absolute positioning of <hr> to wrok
  display: flex;
  flex-direction: column;

  //justify-content: center;
}

h1 {
  position: relative;
  color: $col2;
}
