@import "globals";

:global {
    .fade-in{
        opacity: 0;
        transition: opacity 1s cubic-bezier(0.4, 0, 1, 5);

        &.is-visible{
            opacity: 1;
        }
    }
}

:root {
    --box_margins: 10rem;

    @media (min-width: 800px) {
        --timeline-box-size: 700px;
    }

    @media (max-width: 800px) {
        --timeline-box-size: 90vw;
    }
}

@media (min-width: 800px) {
    .TimelineRow:nth-child(1) .TimelineBox {
        margin-left: calc(1.2 * (100vw - var(--timeline-box-size)) / 2);
    }
    .TimelineRow:nth-child(2) .TimelineBox {
        margin-left: calc(0.8 * (100vw - var(--timeline-box-size)) / 2);
    }
    .TimelineRow:nth-child(3) .TimelineBox {
        margin-left: calc(1.0 * (100vw - var(--timeline-box-size)) / 2);
    }
    .TimelineRow:nth-child(4) .TimelineBox {
        margin-left: calc(1.4 * (100vw - var(--timeline-box-size)) / 2);
    }
}

.TimelineBox{
    box-shadow: 7px 7px 7px 7px black;

    border-radius: 20px;
    padding: 20px;
    background-color: $col2;
    font-size: calc(10px + 1vmin);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin-bottom: 1rem;
    margin-top: 1rem;

    width: var(--timeline-box-size);

    @media (min-width: 900px) {
        position: relative;
        margin-right: var(--box_margins);
        float: left;
    }

    @media (max-width: 900px) {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
    }

    div {
        font-size: 0.9em;
    }

    h3 {
        position: relative;
    }

    h3, div {
        margin-bottom: 0;
        margin-top: 0;
    }

    hr {
        position: relative;
        height: 0.14rem;
        background-color: black;
        border: 0;
        z-index: 10;
        width: 100%;
    }
}
