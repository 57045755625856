@import "globals";


.Content {
    min-height: 100vh;
    display: flex;
    justify-content: flex-start;
    color: white;
    align-items: center;

    @media (min-width: 800px) {
        flex-direction: row;
    }

    @media (max-width: 800px) {
        flex-direction: column;
    }

    > div {
        flex-grow: 1;
    }

    .TopWindowLeft {
        z-index: 20;
        float: right;
        display: flex;
        flex-direction: column;
        align-content: space-evenly;
        align-items: center;

        h2 {
            margin: 0;
            text-align: center;
        }
    }

    .TopWindowBody {
        font-size: calc(20px);

        @media (min-width: 800px) {
            text-align: left;
            line-height: 2.0;
            padding-left: 10vmin;
        }

        @media (max-width: 800px) {
            text-align: center;
            line-height: 1.0;
            padding-left: 0;
        }

        .ContactText{
            @media (min-width: 800px) {
                text-align: left;
            }

            @media (max-width: 800px) {
                text-align: center;
            }
        }

        .Profiles{
            display: flex;
            flex-direction: row;
            //align-items: start;

            @media (min-width: 800px) {
                justify-content: start;
            }

            @media (max-width: 800px) {
                justify-content: space-between;
                vertical-align: text-top;
            }

            img{
                height: 40px;
                @media (min-width: 800px) {
                    margin-right: 40px;
                }
            }
        }

        a {
            all: unset;
            cursor: pointer;


            display: block;
            justify-content: center;

            &:hover {
                text-decoration: underline;
            }
        }

        @media (min-width: 800px) {
            .SmallEmail {
                display: none;
            }
        }

        @media (max-width: 800px) {
            .BigEmail {
                display: none;
                height: 20px;
            }

            .SmallEmail, .SmallEmail * {
                font-size: 35px;
                line-height: 1;
                //display: flex;
                //align-items: center;
                //margin-bottom: auto;
                //margin-right: 40px;
            }
        }
    }

    .PageLogo {
        //height: 30vmin;
        padding: 0px;
        margin: 5vmin;
        border-radius: 10%;
        filter: drop-shadow(5px -5px 5px rgba(0, 0, 0, 0.87));
        box-shadow: 10px 10px 40px 10px $col3 inset;
        width: auto;
        height: auto;

        max-width: 250px;
        max-height: 250px;
    }
}

.arrow {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 90%;
    transform-origin: 90% 50%;
    transform: translate3d(-50%, -50%, 0);

    &:before, &:after {
        background: #fff;
        content: '';
        display: block;
        height: 3px;
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
    }

    &:before {
        transform: rotate(45deg) translateX(-23%);
        transform-origin: top left;
    }

    &:after {
        transform: rotate(-45deg) translateX(23%);
        transform-origin: top right;
    }
}

.Arrows > div:nth-child(1) {
    animation: arrow-movement 2s ease-in-out infinite;
}
.Arrows > div:nth-child(2) {
    animation: arrow-movement 2s 1s ease-in-out infinite;
}

@keyframes arrow-movement {
    0% {
        opacity: 0;
        top: calc(90% - 30px);
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
